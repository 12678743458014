import axiosInstance from '../axiosInstance';

export interface Project {
    company_id: string | undefined;
    id: string;
    project_name: string;
    project_location: string;
    project_start_date: string;
    project_end_date: string;
    project_status_id: string;
    project_description: string;
    task_summary: {
        total_tasks: number;
        done_tasks: number;
        overdue_tasks: number;
        to_do_tasks: number;
        in_progress_tasks: number;
        done_percentage: number;
        tasks_due_in_seven_days: {
            task_name: string;
            assigned_to: number;
            start_date: string;
            end_date: string;
            due_in: string;
        }[];
    }
}

export interface CreateProjectData {
    company_id: string | undefined;
    project_name: string;
    project_location: string;
    project_start_date: string;
    project_end_date: string;
    project_description: string;
}

export interface CreateProjectResponse {
    data: Project;
    message: string;
    error: boolean;
}

export interface UpdateProjectData {
    company_id: string;
    id: string;
    project_name: string;
    project_location: string;
    project_start_date: string;
    project_end_date: string;
}

export interface UpdateProjectResponse {
    data: Project;
    message: string;
    error: boolean;
}

export interface GetAllProjectsResponse {
    data: Project[];
    message: string;
}
export interface GetAProjectResponse {
    data: Project;
    message: string;
}

export const createProject = async (values: CreateProjectData): Promise<CreateProjectResponse> => {
    const response = await axiosInstance.post<CreateProjectResponse>('/projects/create-project', values);
    return response.data;
}

export const updateProject = async (values: UpdateProjectData, id: string): Promise<UpdateProjectResponse> => {
    const response = await axiosInstance.put<UpdateProjectResponse>(`/projects/update-project/${id}`, values);
    return response.data;
}

export const getAllProjects = async (companyId: string): Promise<Project[]> => {
    const response = await axiosInstance.get<GetAllProjectsResponse>(`/projects/?company_id=${companyId}`);
    return response.data.data;
}

export const getAProject = async (companyId: string, id: string): Promise<Project> => {
    const response = await axiosInstance.get<CreateProjectResponse>(`/projects/${id}/?company_id=${companyId}`);
    return response.data.data;
}
