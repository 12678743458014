import axiosInstance from '../axiosInstance';

export interface MaterialRequest {
    company_id: string;
    created_by: string;
    created_on: string;
    paid_by: string;
    paid_at: string;
    vendor_id: string;
    vendor_name: string;
    material_id: string;
    total_quantity: string;
    batch_materials: string;
    materials: {
        material_name: string;
        material_id: string;
        quantity: string;
        unit_cost: string;
    }[]; quantity: string;
    amount: string;
    status: string;
    reviews: [];
    approval: {} | null;
    projectId: string;
    id: string;
}

export interface CreateMaterialRequestData {
    company_id: string;
    vendor_id: string;
    projectId: string;
    materials: {
        material_id: string;
        quantity: string;
        unit_cost: string;
    }[];
}

export interface CreateMaterialRequestResponse {
    data: MaterialRequest;
    message: string;
    error: boolean;
}
export interface UpdateMaterialRequestData {
    company_id: string;
    vendor_id: string;
    materials: {
        material_id: string;
        quantity: string;
        unit_cost: string;
    }[];
    projectId: string;
    id: string;
}

export interface UpdateMaterialRequestResponse {
    data: MaterialRequest;
    message: string;
    error: boolean;
}
export interface GetAllMaterialRequestsResponse {
    data: MaterialRequest[];
    message: string;
    meta: {
        total: number;
        count: number;
        per_page: number;
        current_page: number;
        last_page: number;
        total_pages: number;
    };
}
export interface GetAMaterialRequestResponse {
    data: MaterialRequest;
    message: string;
}
export interface ReviewMaterialRequestData {
    company_id: string;
    projectId: string;
    id: string;
    action: string;
    status: string;
}

export interface ReviewMaterialRequestResponse {
    data: ReviewMaterialRequestData;
    message: string;
    error: boolean;
}
export interface LabourRequest {
    company_id: string;
    created_by: string;
    created_on: string;
    paid_by: string;
    paid_at: string;
    head_name: string;
    stage: string;
    stage_id: string;
    labour_type: string;
    labour_type_id: string;
    bank: string;
    bank_id: string;
    account_number: string;
    amount: string;
    status: string;
    reviews: [];
    projectId: string;
    id: string;
}

export interface CreateLabourRequestData {
    company_id: string;
    head_name: string;
    stage_id: string;
    labour_type_id: string;
    bank_id: string;
    account_number: string;
    amount: string;
    projectId: string;
}

export interface CreateLabourRequestResponse {
    data: LabourRequest;
    message: string;
    error: boolean;
}
export interface UpdateLabourRequestData {
    company_id: string;
    head_name: string;
    stage_id: string;
    labour_type_id: string;
    bank_id: string;
    account_number: string;
    amount: string;
    projectId: string;
    id: string;
}

export interface UpdateLabourRequestResponse {
    data: LabourRequest;
    message: string;
    error: boolean;
}
export interface GetAllLabourRequestsResponse {
    data: LabourRequest[];
    message: string;
    meta: {
        total: number;
        count: number;
        per_page: number;
        current_page: number;
        last_page: number;
        total_pages: number;
    };
}
export interface GetALabourRequestResponse {
    data: LabourRequest;
    message: string;
}
export interface ReviewLabourRequestData {
    company_id: string;
    projectId: string;
    id: string;
    action: string;
    status: string;
}

export interface ReviewLabourRequestResponse {
    data: ReviewLabourRequestData;
    message: string;
    error: boolean;
}
export interface PettyRequest {
    company_id: string;
    created_by: string;
    created_on: string;
    paid_by: string;
    paid_at: string;
    title: string;
    receiver_name: string;
    bank: string;
    bank_id: string;
    account_number: string;
    amount: string;
    status: string;
    reviews: [];
    projectId: string;
    id: string;
}

export interface CreatePettyRequestData {
    company_id: string;
    title: string;
    receiver_name: string;
    bank_id: string;
    account_number: string;
    amount: string;
    projectId: string;
}

export interface CreatePettyRequestResponse {
    data: PettyRequest;
    message: string;
    error: boolean;
}
export interface UpdatePettyRequestData {
    company_id: string;
    title: string;
    receiver_name: string;
    bank_id: string;
    account_number: string;
    amount: string;
    projectId: string;
    id: string;
}

export interface UpdatePettyRequestResponse {
    data: PettyRequest;
    message: string;
    error: boolean;
}
export interface GetAllPettyRequestsResponse {
    data: PettyRequest[];
    message: string;
    meta: {
        total: number;
        count: number;
        per_page: number;
        current_page: number;
        last_page: number;
        total_pages: number;
    };
}
export interface GetAPettyRequestResponse {
    data: PettyRequest;
    message: string;
}
export interface ReviewPettyRequestData {
    company_id: string;
    projectId: string;
    id: string;
    action: string;
    status: string;
}
export interface ReviewPettyRequestResponse {
    data: ReviewPettyRequestData;
    message: string;
    error: boolean;
}

export const createMaterialRequest = async (values: CreateMaterialRequestData): Promise<CreateMaterialRequestResponse> => {
    const response = await axiosInstance.post<CreateMaterialRequestResponse>(`/projects/${values.projectId}/material-requests/create`, values);
    return response.data;
}
export const updateMaterialRequest = async (values: UpdateMaterialRequestData, id: string): Promise<UpdateMaterialRequestResponse> => {
    try {
        const response = await axiosInstance.put<UpdateMaterialRequestResponse>(`/projects/${values.projectId}/material-requests/${id}/update`, values);
        return response.data;
    } catch (error) {
        console.error("Error in updateMaterialRequest API call:", error);
        throw error;
    }
}
export const getAllMaterialRequests = async (companyId: string, projectId: string, page: number): Promise<GetAllMaterialRequestsResponse> => {
    const response = await axiosInstance.get<GetAllMaterialRequestsResponse>(`/projects/${projectId}/material-requests?company_id=${companyId}&page=${page}`);
    return response.data;
}
export const getAMaterialRequest = async (companyId: string, id: string, projectId: string): Promise<MaterialRequest> => {
    const response = await axiosInstance.get<GetAMaterialRequestResponse>(`/projects/${projectId}/material-requests/${id}?company_id=${companyId}`);
    return response.data.data;
}
export const reviewMaterialRequest = async (values: ReviewMaterialRequestData): Promise<ReviewMaterialRequestResponse> => {
    const response = await axiosInstance.post<ReviewMaterialRequestResponse>(`/projects/${values.projectId}/material-requests/${values.id}/act`, values);
    return response.data;
}
export const createLabourRequest = async (values: CreateLabourRequestData): Promise<CreateLabourRequestResponse> => {
    const response = await axiosInstance.post<CreateLabourRequestResponse>(`/projects/${values.projectId}/labour-requests/create`, values);
    return response.data;
}
export const updateLabourRequest = async (values: UpdateLabourRequestData, id: string): Promise<UpdateLabourRequestResponse> => {
    const response = await axiosInstance.put<UpdateLabourRequestResponse>(`/projects/${values.projectId}/labour-requests/${id}/update`, values);
    return response.data;
}
export const getAllLabourRequests = async (companyId: string, projectId: string, page: number): Promise<GetAllLabourRequestsResponse> => {
    const response = await axiosInstance.get<GetAllLabourRequestsResponse>(`/projects/${projectId}/labour-requests?company_id=${companyId}&page=${page}`);
    return response.data;
}
export const getALabourRequest = async (companyId: string, id: string, projectId: string): Promise<LabourRequest> => {
    const response = await axiosInstance.get<GetALabourRequestResponse>(`/projects/${projectId}/labour-requests/${id}?company_id=${companyId}`);
    return response.data.data;
}
export const reviewLabourRequest = async (values: ReviewLabourRequestData): Promise<ReviewLabourRequestResponse> => {
    const response = await axiosInstance.post<ReviewLabourRequestResponse>(`/projects/${values.projectId}/labour-requests/${values.id}/act`, values);
    return response.data;
}
export const createPettyRequest = async (values: CreatePettyRequestData): Promise<CreatePettyRequestResponse> => {
    const response = await axiosInstance.post<CreatePettyRequestResponse>(`/projects/${values.projectId}/petty-cash-requests/create`, values);
    return response.data;
}
export const updatePettyRequest = async (values: UpdatePettyRequestData, id: string): Promise<UpdatePettyRequestResponse> => {
    const response = await axiosInstance.put<UpdatePettyRequestResponse>(`/projects/${values.projectId}/petty-cash-requests/${id}/update`, values);
    return response.data;
}
export const getAllPettyRequests = async (companyId: string, projectId: string, page: number): Promise<GetAllPettyRequestsResponse> => {
    const response = await axiosInstance.get<GetAllPettyRequestsResponse>(`/projects/${projectId}/petty-cash-requests?company_id=${companyId}&page=${page}`);
    return response.data;
}
export const getAPettyRequest = async (companyId: string, id: string, projectId: string): Promise<PettyRequest> => {
    const response = await axiosInstance.get<GetAPettyRequestResponse>(`/projects/${projectId}/petty-cash-requests/${id}?company_id=${companyId}`);
    return response.data.data;
}
export const reviewPettyRequest = async (values: ReviewPettyRequestData): Promise<ReviewPettyRequestResponse> => {
    const response = await axiosInstance.post<ReviewPettyRequestResponse>(`/projects/${values.projectId}/petty-cash-requests/${values.id}/act`, values);
    return response.data;
}
