import axiosInstance from '../axiosInstance';

export interface Reports {
    projects_summary: {
        total_tasks: number;
        done_tasks: number;
        in_progress_tasks: number;
        to_do_tasks: number;
        overdue_tasks: number;
        done_percentage: number;
        in_progress_percentage: number;
        to_do_percentage: number;
        overdue_percentage: number;
    };
    budgets_summary: {
        total_budgets: number;
        material_budgets: number;
        labour_budgets: number;
        petty_cash_budgets: number;
        material_spent: number;
        labour_spent: number;
        petty_cash_spent: number;
    };
    materials_by_vendor_summary: {
        total_materials_from_vendors: number;
        total_amount_from_vendors: number;
        materials_by_vendor: {
            vendor: string,
            total_materials: number;
            total_amount: number;
        }[];
    };
    monthly_spent_summary: {
        month: string;
        total_labour: number;
        total_materials: number;
        total_petty_cash: number;
        total_spent: number;
    }[];
    petty_cash_requests_by_title_summary: {
        total_petty_cash_amount: number;
        total_petty_cash_requests: number;
        petty_cash_requests_by_title: {
            petty_cash_title: string;
            total_amount: number;
            total_requests: number;

        }[];
    };
    materials_paid_summary: {
        daily_paid_materials: {
            material_name: string;
            total_cost: number;
            total_quantity: number;

        }[];
        weekly_paid_materials: {
            material_name: string;
            total_cost: number;
            total_quantity: number;

        }[];
        monthly_paid_materials: {
            material_name: string;
            total_cost: number;
            total_quantity: number;

        }[];
        total_daily_cost: number;
        total_daily_usage: number;
        total_monthly_cost: number;
        total_monthly_usage: number;
        total_weekly_cost: number;
        total_weekly_usage: number;
    };
    labour_paid_summary: {
        daily_paid_labour: {
            labour_type_id: number;
            labour_type: string;
            total_cost: string;
        }[];
        weekly_paid_labour: {
            labour_type_id: number;
            labour_type: string;
            total_cost: string;
        }[];
        monthly_paid_labour: {
            labour_type_id: number;
            labour_type: string;
            total_cost: string;
        }[];
        total_daily_cost: number;
        total_monthly_cost: number;
        total_weekly_cost: number;
    };
}

export interface GetAllReportsResponse {
    data: Reports;
    message: string;
}

export const getAllReports = async (companyId: string, projectId: string): Promise<GetAllReportsResponse> => {
    const response = await axiosInstance.get<GetAllReportsResponse>(`/projects/${projectId}/reports?company_id=${companyId}`);
    return response.data;
}