import React, { useEffect, useState } from "react";
import { Image, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import DashVector from "../../assets/images/dash-vector.png";
import Person from "../../assets/images/Person.svg";
import { getAUserAsync } from "../../slices/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import User from "../../assets/images/user-img.png";
import { RootState } from "../../store/store";
import { hasPermission } from "../../util/multiplePermissionsChecker";
import { extractPermissionNames } from '../../util/transformPermissions';
import MenuIcon from '@mui/icons-material/Menu';

interface SideBarProps {
	userId: string;
}

const SideBar: React.FC<SideBarProps> = ({
	userId,
}) => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const storedProjectId = localStorage.getItem("projectId");
	const activeProjectId = storedProjectId || 'default-project-id';
	const userData = useAppSelector((state: any) => state.user?.userData?.user);
	const isAdmin = userData?.system_role === 'admin';
	const permissions = isAdmin ? null : extractPermissionNames(userData?.permissions?.permissions || []);
	const companyName = useAppSelector((state: any) => state.user.userData?.user.company_name);
	const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || "");

	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth <= 1320) {
				setIsCollapsed(true);
				setIsSidebarOpen(false);
			} else {
				setIsCollapsed(false);
				setIsSidebarOpen(true);
			}
		};
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleSidebar = () => {
		setIsSidebarOpen((prevState) => !prevState);
	};
	useEffect(() => {
		if (companyId && userId) {
			dispatch(getAUserAsync({ companyId, id: userId }));
		}
	}, [dispatch, companyId, userId]);

	const canViewSchedule = hasPermission(permissions, ['view-schedule', 'edit-schedule', 'create-schedule', 'delete-schedule']);
	const canViewBudget = hasPermission(permissions, ['view-budget', 'edit-budget', 'create-budget', 'delete-budget']);
	const canViewRequest = hasPermission(permissions, ['view-request', 'edit-request', 'create-request', 'delete-request', 'approve/reject-request', 'review/reject-request']);
	const canViewAccount = hasPermission(permissions, ['view-account', 'pay-account']);
	const canViewReport = hasPermission(permissions, ['view-report']);
	const canViewInventory = hasPermission(permissions, ['view-inventory', 'edit-inventory', 'create-inventory', 'confirm-inventory']);

	console.log("user permissions", permissions)
	return (
		<>
			{isCollapsed && (
				<button className="hamburger" onClick={toggleSidebar}>
					<MenuIcon />
				</button>
			)}
			<aside
				className={`sidebar bg-color-9 mt-4 ${isSidebarOpen ? 'open' : 'closed'}`}
			>   			<Row className="sidebar-company-card">
					<Col md={2} sm={2} xs={2}>
						<Image src={User} className="mt-1" />
					</Col>
					<Col md={10} sm={10} xs={10}>
						<Row>
							<small className="fw-bold text-start text-color-7 ">{companyName}</small>
						</Row>
						<Row>
							<div className="d-flex sidebar-text-1 ">
								<p>Free    .</p>
								<div>
									<Image src={Person} className="mx-2 mb-1" />
								</div>
								<p>1</p>
							</div>

						</Row>
					</Col>
				</Row>
				<div className="sidebar-menu">

					<Link
						to={`/dashboard/${activeProjectId}`}
						className={`sidebar-link ${location.pathname === `/dashboard/${activeProjectId}`
							? "active"
							: ""
							}`}
					>
						<span className="sidebar-text sidebar-link">
							<Image src={DashVector} className="sidebar-icon" />

							Dashboard
						</span>
					</Link>
					{canViewSchedule && (
						<Link
							to={`/project-management/${activeProjectId}`}
							className={`sidebar-link ${location.pathname === `/project-management/${activeProjectId}`
								? "active"
								: ""
								}`}
						>
							<span className="sidebar-text sidebar-link">
								<Image src={DashVector} className="sidebar-icon" />
								Project management
							</span>
						</Link>
					)}
					{canViewBudget && (
						<Link
							to={`/budget/${activeProjectId}`}
							className={`sidebar-link ${location.pathname === `/budget/${activeProjectId}` ? "active" : ""
								}`}
						>
							<span className="sidebar-text sidebar-link">
								<Image src={DashVector} className="sidebar-icon" />
								Budget
							</span>
						</Link>
					)}
					{canViewRequest && (

						<Link
							to={`/procurement/${activeProjectId}`}
							className={`sidebar-link ${location.pathname === `/procurement/${activeProjectId}`
								? "active"
								: ""
								}`}
						>
							<span className="sidebar-text sidebar-link">
								<Image src={DashVector} className="sidebar-icon" />
								Procurement
							</span>
						</Link>
					)}
					{canViewAccount && (

						<Link
							to={`/accounts/${activeProjectId}`}
							className={`sidebar-link ${location.pathname === `/accounts/${activeProjectId}` ? "active" : ""
								}`}
						>
							<span className="sidebar-text sidebar-link">
								<Image src={DashVector} className="sidebar-icon" />
								Accounts
							</span>
						</Link>
					)}
					{canViewReport && (

						<Link
							to={`/report/${activeProjectId}`}
							className={`sidebar-link ${location.pathname === `/report/${activeProjectId}` ? "active" : ""
								}`}
						>
							<span className="sidebar-text sidebar-link">
								<Image src={DashVector} className="sidebar-icon" />
								Report
							</span>
						</Link>
					)}
					{canViewInventory && (

						<Link
							to={`/inventory/${activeProjectId}`}
							className={`sidebar-link ${location.pathname === `/inventory/${activeProjectId}`
								? "active"
								: ""
								}`}
						>
							<span className="sidebar-text sidebar-link">
								<Image src={DashVector} className="sidebar-icon" />
								Inventory
							</span>
						</Link>
					)}
				</div>
			</aside>
		</>
	);
}

export default SideBar;
