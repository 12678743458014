import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    Budget, createBudget, CreateBudgetData, CreateBudgetResponse,
    getAllBudgets,
} from "../../services/budget/budgetService";

export interface BudgetState {
    createBudgetResponse?: CreateBudgetResponse | null;
    budgets: Budget[];
}

const initialState: BudgetState = {
    createBudgetResponse: null,
    budgets: [],
}

export const createBudgetAsync = createAsyncThunk(
    'budgets/createBudget',
    async (createBudgetData: CreateBudgetData, { dispatch, rejectWithValue }) => {
        try {
            const response = await createBudget(createBudgetData);
            return response;
        } catch (error: any) {
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({ error: true, message: "An unexpected error occurred" });
        }
    }
)

export const getAllBudgetsAsync = createAsyncThunk(
    'budgets/getAllBudgets',
    async ({ companyId, projectId }: { companyId: string; projectId: string }) => {
        try {
            const budgets = await getAllBudgets(companyId, projectId);
            return budgets;
        } catch (error) {
            console.error('Error fetching budgets:', error);
            throw error;
        }
    }
)

const budgetSlice = createSlice({
    name: "budget",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createBudgetAsync.fulfilled, (state, action) => {
            state.createBudgetResponse = action.payload;
        })
        builder.addCase(getAllBudgetsAsync.pending, (state, action) => {
            state.budgets = [];
        })
        builder.addCase(getAllBudgetsAsync.fulfilled, (state, action) => {
            state.budgets = action.payload;
        })
        builder.addCase(getAllBudgetsAsync.rejected, (state, action) => {
            state.budgets = [];
        })
    }
})

export default budgetSlice.reducer;