import React from "react";

import { Button, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface UserNotPaidProps {
	closeModal: () => void;
	show: boolean;
}

const UserNotPaid: React.FC<UserNotPaidProps> = ({ closeModal, show }) => {
	return (
		<Modal centered show={show} onHide={closeModal}>
			<div className="d-inline-flex justify-content-between align-items-center px-2">
				<h4 className="confirm-header">This option is only for paid users</h4>
				<button
					onClick={closeModal}
					className="confirm-header bg-transparent border-0 p-0"
				>
					{" "}
					X{" "}
				</button>
			</div>
			<Modal.Body>
				<Row className="w-100">
					<p className="text-center w-100">
						Click on the button below to subscribe to a paid plan!
					</p>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Row className="d-inline-flex justify-content-center align-items-center">
					<Link to="/settings-billings">
						<Button>Subscribe</Button>
					</Link>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};

export default UserNotPaid;
