import axiosInstance from '../axiosInstance';

export interface TaskData {
	projectId: string | undefined | null;
	company_id: string | null;
	task_name: string;
	assigned_to: number;
	start_date: string;
	end_date: string;
	task_status: string;
	task_color: string;
}

export interface CreateTaskResponse {
	data: TaskData;
	message: string;
	error: boolean;
}

export interface Task extends TaskData {
	id: string;
}

export interface GetAllTasksResponse {
	data: Task[];
	message: string;
	meta: {
		total: number;
		count: number;
		per_page: number;
		current_page: number;
		last_page: number;
		total_pages: number;
	};
}
export interface UpdateTaskResponse {
	data: Task[];
	message: string;
	error: boolean;
}

export interface GetATaskResponse {
	data: TaskData;
	message: string;
	error: boolean;
}

export interface DeleteTaskResponse {
	message: string;
	error: boolean;
}

export const createTask = async (
	data: TaskData,
): Promise<CreateTaskResponse> => {
	try {
		const response = await axiosInstance.post(
			`/projects/${data.projectId}/create-task`,
			data,
		);
		return response.data;
	} catch (error: any) {
		return error.response.data;
	}
};

export const updateTask = async (data: Task): Promise<UpdateTaskResponse> => {
	try {
		const response = await axiosInstance.put(
			`/projects/${data.projectId}/update-task/${data.id}`,
			data,
		);
		return response.data;
	} catch (error: any) {
		return error.response.data;
	}
};

export const getAllTasks = async (
	company_id: string,
	projectId: string,
	page: number,
): Promise<GetAllTasksResponse> => {
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/tasks?company_id=${company_id}&page=${page}`,
		);
		return response.data;
	} catch (error: any) {
		return error.response.data;
	}
};

export const getATask = async (
	id: string,
	company_id: string,
	projectId: string,
): Promise<GetATaskResponse> => {
	try {
		const response = await axiosInstance.get(
			`/projects/${projectId}/tasks/${id}?company_id=${company_id}`,
		);
		return response.data;
	} catch (error: any) {
		return error.response.data;
	}
};

export const deleteTask = async (
	id: string,
	projectId: string,
	company_id: string,
): Promise<DeleteTaskResponse> => {
	try {
		const response = await axiosInstance.delete(
			`/projects/${projectId}/delete-task/${id}?company_id=${company_id}`,
		);
		return response.data;
	} catch (error: any) {
		return error.response.message;
	}
};
