import React, { useEffect } from 'react';
import Layout from "../../components/layouts/layout";
import { Table, Row, Col, Card } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { getAProjectAsync } from '../../slices/project/projectSlice';
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { RootState } from '../../store/store';
import { useParams } from 'react-router-dom';
import { format } from "date-fns";

const DashBoard: React.FC = () => {
	const { projectId } = useParams<{ projectId: string }>();
	const dispatch = useAppDispatch();
	const companyId = useAppSelector((state: RootState) => state.auth.user?.company_id || '');
	const projectData = useAppSelector((state: any) => state.project.projectData);

	useEffect(() => {
		if (projectId && companyId) {
			dispatch(getAProjectAsync({ companyId, id: projectId }));
		}
	}, [dispatch, projectId, companyId]);



	return (
		<div className="bg-color-8">
			<Layout>
				<div className="mt-5 p-5">
					<h3>Dashboard</h3>
					<>
						<Row className="bg-color-9 p-4 mt-3">
							<Col md={9} sm={6} xs={6} >
								<h4>{projectData?.project_name}</h4>
								<h6>{projectData?.project_location}</h6>
							</Col>
							<Col md={3} sm={6} xs={6} className='text-center dash-border'>
								<p className='dash-text-4 mb-0'>Project completion</p>
								<span className='mt-2'>
									<CircularProgress
										variant="determinate"
										size="30px"
										value={projectData?.task_summary.done_percentage}
									/>
								</span>

								<span className='dash-texts-5 mx-2 mt-0'>{projectData?.task_summary.done_percentage.toFixed()}%</span>
							</Col>

						</Row>
						<Row className="mt-4">
							<h6 className="text-color-1 dash-texts-2">Task summary</h6>
						</Row>
						<Row className='bg-color-9 p-4'>
							<Col md={4} sm={4} xs={12} className='mt-2'>
								<Card className='bg-color-5 text-color-1 border-0 p-4'>
									<h1 className='dash-texts-1'>{projectData?.task_summary?.done_tasks || 0}</h1>
									<p className='dash-texts-2'>Task completed</p>
								</Card>
							</Col>
							<Col md={4} sm={4} xs={12} className='mt-2'>
								<Card className='bg-color-10 text-color-8 border-0 p-4'>
									<h1 className='dash-texts-1'>{projectData?.task_summary?.to_do_tasks || 0}</h1>
									<p className='dash-texts-2'>Task pending</p>
								</Card>
							</Col>
							<Col md={4} sm={4} xs={12} className='mt-2'>
								<Card className='bg-color-7 text-color-6 border-0 p-4'>
									<h1 className='dash-texts-1'>{projectData?.task_summary?.overdue_tasks || 0}</h1>
									<p className='dash-texts-2'>Task due</p>
								</Card>
							</Col>
						</Row>
						<Row className="mt-4">
							<h6 className="text-color-1 dash-texts-2">Task due</h6>
						</Row>
						<div className="bg-color-9 px-5 mt-2 ">
							<Table borderless responsive className='mt-3'>
								<thead>
									<tr>
										<th className='text-color-1 bg-color-3'>Task name </th>
										<th className='text-color-1 bg-color-3'>Assigned to </th>
										<th className='text-color-1 bg-color-3'>Planned start date </th>
										<th className='text-color-1 bg-color-3'>Planned end date </th>
										<th className='text-color-1 bg-color-3'>Due in</th>
									</tr>
								</thead>
								<tbody>
									{projectData?.task_summary?.tasks_due_in_seven_days?.length > 0 ? (
										projectData.task_summary.tasks_due_in_seven_days.map((task: any) => (
											<tr key={task.id}>
												<td>{task.task_name}</td>
												<td>{task.assigned_to}</td>
												<td>{format(new Date(task.start_date), "dd/MM/yy")}</td>
												<td>{format(new Date(task.end_date), "dd/MM/yy")}</td>
												<td>{task.due_in}</td>
											</tr>

										))
									) : (
										<tr>
											<td colSpan={4} className="text-center">No tasks due in the next 7 days.</td>
										</tr>
									)}
								</tbody>

							</Table>
						</div>
					</>
				</div>
			</Layout>
		</div>
	);
}

export default DashBoard;
